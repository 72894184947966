@import 'src/styles/styles';

.ogp-footer-container {
  background: $ogp-black;
  width: 100%;
  padding-top: 4em;
  padding-bottom: 4em;

  .footer-left {
    p {
      font-size: 14px;
      color: $ogp-grey;
      margin-bottom: 1em;
    }
    img {
      width: 180px;
      height: auto;
    }

    margin-bottom: 1em;
  }

  .footer-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 1em;

    .social-icons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1em;

      .icon {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          color: white;
          text-decoration: none;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            color: white;
          }

          svg {
            fill: white;
          }

          i {
            font-size: 1.2em;
          }

          img {
            display: block;
            width: 30px;
            height: 30px;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
    .copyright-text {
      font-size: 14px;
      color: $ogp-grey;
      width: 100%;
    }
  }
}

@media screen and (min-width: 760px) {
  .ogp-footer-container {
    .footer-left {
      margin-bottom: 0;
    }
    .footer-right {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 0;

      .copyright-text {
        text-align: right;
      }
    }
  }
}
