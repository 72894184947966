.card {
  background: white;
  border-radius: $base-border-radius * 2;
  box-sizing: border-box;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  .card-body {
    padding: 2em 2em;

    &.full-width {
      padding: 2em 0;
    }
  }
}
