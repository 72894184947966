@import 'src/styles/styles';

.mastheadContainer {
  width: 100%;
  background: $brand-base-100;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .mastheadContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 32px;

    .sgCrest {
      width: 18px;
      margin-right: 10px;
    }

    p {
      font-size: 12px;
    }
  }
}
