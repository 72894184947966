$palette-secondary-100: #f6f7fa;
$palette-secondary-200: #f7caca;
$palette-secondary-300: #f2a7a7;
$palette-secondary-400: #ed8585;
$palette-secondary-500: #e86363;
$palette-secondary-600: #bf5151;
$palette-secondary-700: #994141;
$palette-secondary-800: #6f2f2f;
$palette-secondary-900: #451d1d;

$palette-primary-100: #f6f7fa;
$palette-primary-200: #dfe1f2;
$palette-primary-300: #bcbfe3;
$palette-primary-400: #868ac3;
$palette-primary-500: #505798;
$palette-primary-600: #3b4183;
$palette-primary-700: #2f3476;
$palette-primary-800: #222757;
$palette-primary-900: #1b1e44;

$palette-neutral-100: #fbfcfd;
$palette-neutral-200: #f0f0f1;
$palette-neutral-300: #e1e2e4;
$palette-neutral-400: #c9cccf;
$palette-neutral-500: #abadb2;
$palette-neutral-600: #999b9f;
$palette-neutral-700: #636467;
$palette-neutral-800: #48494b;
$palette-neutral-900: #242425;

$palette-danger-100: #fff8f8;
$palette-danger-200: #f8eaea;
$palette-danger-300: #e8c1c1;
$palette-danger-400: #d88888;
$palette-danger-500: #c05050;
$palette-danger-600: #ad4848;
$palette-danger-700: #9a4040;
$palette-danger-800: #733030;
$palette-danger-900: #602828;

$palette-success-100: #f0f8f3;
$palette-success-200: #e2f1e8;
$palette-success-300: #add8be;
$palette-success-400: #77bf93;
$palette-success-500: #41a669;
$palette-success-600: #0c8c3f;
$palette-success-700: #097333;
$palette-success-800: #075c29;
$palette-success-900: #05431e;
